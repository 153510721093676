// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$mobbsign-web-demo-ng-primary: mat.define-palette(mat.$indigo-palette);
$mobbsign-web-demo-ng-accent: mat.define-palette(
  mat.$pink-palette,
  A200,
  A100,
  A400
);

// The warn palette is optional (defaults to red).
$mobbsign-web-demo-ng-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$mobbsign-web-demo-ng-theme: mat.define-light-theme(
  (
    color: (
      primary: $mobbsign-web-demo-ng-primary,
      accent: $mobbsign-web-demo-ng-accent,
      warn: $mobbsign-web-demo-ng-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($mobbsign-web-demo-ng-theme);

/* You can add global styles to this file, and also import other style files */

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Nunito", "Open-Sans", sans-serif, Roboto, "Helvetica Neue";
  height: 80vh;
}

button {
  font-family: "Nunito", "Open-Sans", sans-serif, Roboto, "Helvetica Neue";
}

.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.text-left {
  text-align: left !important;
}

.clickable {
  text-decoration: underline !important;
  cursor: pointer;
}

.font-weight-bold {
  font-weight: bold;
}

.mt-5 {
  margin-top: 4rem!important;
}

.mt-4 {
  margin-top: 3rem!important;
}

.mt-3 {
  margin-top: 2rem!important;
}

.pt-4 {
  padding-top: 3rem!important;
}

.vh-80 {
  height: 80vh!important;
}

.vh-40 {
  height: 40vh!important;
}

.text-align-center {
  text-align: center!important;
}

.h-100 {
  height: 100%;
}

.ph-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.pl-1 {
  padding-left: 5px;
}

.pt-1 {
  padding-top: 15px;
}

.m-0 {
  margin: 0!important;
}

// Flexbox
.container {
  display: flex;
}

.item {
  position: relative;
  width: 100%;
  max-width: 100%;
  flex: 0 0 100%;
}

.flex-column {
  flex-direction: column;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}
