:root {
  --default-mobbsign-annotate-tool-button-bg-color: #f0f0f0;
  --default-mobbsign-sign-tool-button-bg-color: #f0f0f0;
  --default-mobbsign-discard-button-bg-color: #f0f0f0;
  --default-mobbsign-confirm-button-bg-color: #f0f0f0;
  --default-mobbsign-done-button-bg-color: #f0f0f0;
}

#mobbsign-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
}

#mobbsign-sign {
  display: none;
}

.spinner {
  /* Spinner size and color */
  width: 5rem;
  height: 5rem;
  border-width: 12px;
  border-top-color: #aaa;
  border-left-color: #aaa;

  /* Additional spinner styles */
  animation: spinner 400ms linear infinite;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-style: solid;
  border-width: 2px;
  border-radius: 50%;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
}

/* Animation styles */
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.modal-mobbsign-no-tactile-device {
  display: flex;
  flex-direction: row;
  visibility: hidden;
  position: absolute; /* Stay in place */
  z-index: 9999999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.modal-content-mobbsign-no-tactile-device {
  background-color: #fefefe;
  margin: auto;
  flex-direction: column;
  display: flex;
  padding: 5px;
  box-sizing: border-box;
  text-align: center;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  border: 1px solid lightgray;
  border-top: 5px solid #58abb7;
  border-radius: 10px;
  width: 45%;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  font-size: 14px;
  font-weight: bold;
  width: 20%;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.primary,
.primary.active {
  color: #007bff!important;
}

.success,
.success.active {
  color: #28a745!important;
}

.warning,
.warning.active {
  color: #ffc107!important;
}

.danger,
.danger.active {
  color: #dc3545!important;
}

.info,
.info.active {
  color: #17a2b8!important;
}

.dark,
.danger.active {
  color: #343a40!important;
}

.light,
.light.active {
  color: #f8f9fa!important;
}

.primary:hover,
.primary.active {
  background-color: #cce5ff!important;
}

.success:hover,
.success.active {
  background-color: #e8faec!important;
}

.warning:hover,
.warning.active {
  background-color: #fff7df!important;
}

.danger:hover,
.danger.active {
  background-color: #fcedee!important;
}

.info:hover,
.info.active {
  background-color: #e6f9fc!important;
}

.dark:hover,
.danger.active {
  background-color: #f3f4f5!important;
}

.light:hover,
.light.active {
  background-color: #edeff2!important;
}

.default-mobbsign-annotate-tool-button:hover,
.default-mobbsign-annotate-tool-button.active {
  background-color: var(--default-mobbsign-annotate-tool-button-bg-color);
}

.default-mobbsign-sign-tool-button:hover,
.default-mobbsign-sign-tool-button.active {
  background-color: var(--default-mobbsign-sign-tool-button-bg-color);
}

.default-mobbsign-discard-button:hover,
.default-mobbsign-discard-button.active {
  background-color: var(--default-btn-discard-bg-color);
}

.default-mobbsign-confirm-button:hover,
.default-mobbsign-confirm-button.active {
  background-color: var(--default-mobbsign-confirm-button-bg-color);
}

.default-mobbsign-done-button:hover,
.default-mobbsign-done-button.active {
  background-color: var(--default-mobbsign-done-button-bg-color);
}

.mobbsign-label {
  cursor: pointer;
}